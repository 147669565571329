import * as React from "react"
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
import BuildingsEdit from "@components/buildings/Edit"
import Seo from "@components/seo"
import "../../styles/index.scss"

const AdditionalParams = {
  endPointName: "buildings.edit"
}

const BuildingsEditPage = (props) => (
  <>
    <Seo title="Edit Building" />
    <div className="main-wrapper">
      <Sidebar menuActive="buildings" 
        action="Edit"/>
      <BuildingsEdit 
        menu="Edit Buildings" 
        parentPath="buildings" 
        pageType="Edit"
        additionalParams={AdditionalParams}/>
    </div>
  </>
)

export default BuildingsEditPage;
