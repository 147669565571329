import React, { useEffect, useState } from "react";
import { Button, Tabs, Tab, Row, Col, Form, Alert } from 'react-bootstrap'
import Select from 'react-select';
//import "../google-map/style/app.css"
//import GoogleMapReact from 'google-map-react';
import GoogleMap from "../google-map/map";
import 'react-data-table-component-extensions/dist/index.css';
import './assets/styles/_tabs.scss';
import CommonEditItemComp from "../common/Edit";
import {
  HandleChange, GetAllItems, HandleAttachmentField, CheckFieldValidity, CheckFormValidity,
  DefaultSelectValue, ClearAttachmentField
} from "../../utility/Form";
import { set, isEmpty, get, find, merge, sortBy } from "lodash";
import ModalPopUp from "../common/modal/Modal";
import StatcTable from "../common/StaticTable";
import CommonTableComp from "../common/Table";
import { navigate } from "@reach/router";
import TextEditor from "../text-editor";
import CurrencyJson from "../../config/currency.json"
import ConfirmOnExitComp from "../common/ConfirmOnExit";
import { isBrowser } from "../common/ggfx-client/utility";
import CurrencyFormat from 'react-currency-format';

// Map
const defaultProps = {
  center: {
    lat: 51.509865,
    lng: -0.118092,
  },
  zoom: 11,
}
const AnyReactComponent = ({ text }) => <div>{text}</div>
// Map

const EditFormControls = (props) => {
  //Default fields values
  const [DefaultFields, SetDefaultFields] = useState({
    price: null,
    completed_date: null,
    exchanged_date: null,
    bedroom: null,
    bathroom: null,
    reception: null,
    floorarea_min: null,
    floorarea_max: null,
    amenities: [],
    max_price: null,
    assistant_building_managers: [],
    extra_regions: [],
    main_building_manager: null,
  });

  //Select box validations
  const SelectBoxFieldDefaultErrors = {
    main_region: {
      hasError: true,
      type: 'required'
    }
  };
  const [selectBoxFieldErrors, setSelectBoxFieldErrors] = useState({});
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [mapActionType, setMapActionType] = useState('lat-lng');
  const [highLightFeatures, setHighLightFeatures] = useState([""]);
  const [agentNotes, setAgentNotes] = useState([""]);
  const [uploadedData, setUploadedData] = useState({});
  const [allUploadedItems, setAllUploadedItems] = useState([]);
  const [buildingManagers, setBuildingManagers] = useState([]);
  const [regions, setRegions] = useState([]);
  const [brochureUrl, setBrochureUrl] = useState("");
  const [mapLoc, setMapLoc] = useState({ lat: 51.509865, lng: -0.118092 });
  const [editItemMapLoc, setEditItemMapLoc] = useState({});
  const [mainBuildingManager, setMainBuildingManager] = useState(DefaultSelectValue);
  const [assistantBuildingManagers, setAssistantBuildingManagers] = useState([]);
  const [mainRegion, setMainRegion] = useState(DefaultSelectValue);
  const [extraRegions, setExtraRegions] = useState([]);
  const [offered, setOffered] = useState(DefaultSelectValue);
  const [selFacilities, setSelFacilities] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [isEnabled, setIsEnabled] = useState(false);
  const [isShowOnSite, setIsShowOnSite] = useState(false);
  const [propertyTableQueryParam, setPropertyTableQueryParam] = useState();
  const [isChildReload, setIsChildReload] = useState(false);
  const [currencyOption, setCurrencyOption] = useState();
  const [maxCurrencyOption, setMaxCurrencyOption] = useState();

  //Get building managers
  const GetBuildingManagers = async () => {
    try {
      const { data } = await GetAllItems('building-managers.getAll', { query: "_limit=-1&_sort=name:ASC" });
      console.log('AddFormControls GetBuildingManagers', data);
      let Managers = data.results.map(manager => { return { value: manager.id, label: manager.name } });
      Managers.splice(0, 0, DefaultSelectValue);
      setBuildingManagers(Managers)
    } catch (error) {
      console.log('AddFormControls GetBuildingManagers error', error);
    }
  };

  //Get regions
  const GetRegions = async () => {
    try {
      const { data } = await GetAllItems('regions.getAll', { query: "_limit=-1&_sort=name:ASC" });
      console.log('AddFormControls GetRegions', data);
      let Regions = data.results.map(region => { return { value: region.id, label: region.name } });
      Regions.splice(0, 0, DefaultSelectValue);
      setRegions(Regions)
    } catch (error) {
      console.log('AddFormControls GetRegions error', error);
    }
  };

  //Get settings
  const GetSettings = async () => {
    try {
      const { data } = await GetAllItems('settings.getAll', { query: "_limit=-1" });
      setCurrencyOption(data?.results[0]?.currency);
      setMaxCurrencyOption(data?.results[0]?.currency)
      set(DefaultFields, 'currency', data?.results[0]?.currency);
    } catch (error) {
      console.log('AddFormControls GetBuildingManagers error', error);
    }
  };

  //Get facilities
  const GetFecilities = async () => {
    try {
      const { data } = await GetAllItems('facilities.getAll', { query: "_limit=-1&_sort=facility:ASC" });
      console.log('AddFormControls GetFecilities', data);
      let Facilities = data.results.map(facility => { return { value: facility.id, label: facility.facility } });
      // Facilities.splice(0,0, DefaultSelectValue);
      setFacilities(Facilities)
    } catch (error) {
      console.log('AddFormControls GetBuildingManagers error', error);
    }
  };

  useEffect(() => {
    GetBuildingManagers();
    GetRegions();
    GetSettings();
    GetFecilities();
  }, []);

  useEffect(() => {
    try {
      setPrice(props.editItem?.price?.toString() || "");
      setMaxPrice(props.editItem?.max_price?.toString() || "")
      let mediaFiles = [...(get(props, 'editItem.images') || []), ...(get(props, 'editItem.documents') || [])]
      mediaFiles && setAllUploadedItems([...mediaFiles]);
      const SelAmenities = props?.editItem?.amenities && props.editItem.amenities.map(facility => {
        return { value: facility.id, label: facility.facility }
      }) || [];
      HandleMultiSelect(SelAmenities, 'amenities', true);
      props?.editItem?.special && setHighLightFeatures(props.editItem.special || [""])
      props?.editItem?.agent_notes && setAgentNotes(props.editItem.agent_notes || [""])
      if(!isEmpty(props?.editItem?.main_building_manager)) {
        setMainBuildingManager({
          value: props.editItem.main_building_manager.id, label: props.editItem.main_building_manager.name
        });
        set(DefaultFields, `main_building_manager`, props.editItem.main_building_manager.name);
      }

      const SelAssistantBuildingManagers = props?.editItem?.assistant_building_managers &&
        props.editItem.assistant_building_managers.map(manager => {
          return { value: manager.id, label: manager.name }
        }) || [];
      HandleMultiSelect(SelAssistantBuildingManagers, 'assistant_building_managers', true);
      if(props?.editItem?.main_region) {
        HandleMultiSelect({
          value: props.editItem.main_region.id, label: props.editItem.main_region.name
        }, 'main_region');
      }

      const SelExtraRegions = props?.editItem?.extra_regions &&
        props.editItem.extra_regions.map(region => {
          return { value: region.id, label: region.name }
        }) || [];
      HandleMultiSelect(SelExtraRegions, 'extra_regions', true);
      props?.editItem?.brochures && setBrochureUrl(props?.editItem?.brochures.url)
      props?.editItem?.status && setOffered({
        value: props.editItem.status, label: props.editItem.status
      })
      props?.editItem?.status && set(DefaultFields, 'status', props.editItem.status || "");
      let checkboxValuesTemp = {
        'is_enabled': get(props, 'editItem.is_enabled'),
        'is_show_on_evening_standard_site': get(props, 'editItem.is_show_on_evening_standard_site'),
      };
      setCheckboxValues(checkboxValuesTemp);
      props?.editItem?.id && setPropertyTableQueryParam(props.editItem.id);
      setEditItemMapLoc({
        lat: props?.editItem?.latitude || 51.509865,
        lng: props?.editItem?.longitude || -0.118092,
      });
      setLatitude(props?.editItem?.latitude || 51.509865);
      setLongitude(props?.editItem?.longitude || -0.118092);
      if (props?.editItem?.latitude && props?.editItem?.longitude) {
        setMapLoc({ lat: props?.editItem?.latitude, lng: props?.editItem?.longitude })
      }
      props?.editItem?.currency && setCurrencyOption(props?.editItem?.currency)
      set(DefaultFields, 'currency', props?.editItem?.currency || {});
      props?.editItem?.max_currency && setMaxCurrencyOption(props?.editItem?.max_currency)
      set(DefaultFields, 'max_currency', props?.editItem?.max_currency || {});
      set(DefaultFields, 'child_properties', props?.editItem?.child_properties);

      //Set medias
      set(DefaultFields, 'images', props.editItem.images || []);
      set(DefaultFields, 'documents', props.editItem.documents || []);
      set(DefaultFields, 'brochures', props.editItem.brochures || []);
      set(DefaultFields, 'price', props.editItem?.price);
      set(DefaultFields, 'max_price', props.editItem?.max_price);
    } catch (error) {
      console.log('error', error)
    }
  }, [props.editItem]);

  useEffect(() => {
    let UploadedItems = [...allUploadedItems].filter(item => !isEmpty(item));
    if (uploadedData.isUpdate || uploadedData.isDelete) {
      let itemsByField = [], itemsByOtherFields = [];
      UploadedItems.forEach(element => {
        if (element.field === uploadedData.field)
          return itemsByField.push(element);
        itemsByOtherFields.push(element);
      });
      if (uploadedData.isUpdate) {
        itemsByField = itemsByField.map((item, index) => {
          if (index === uploadedData.index) {
            const ItemMerged = { ...item, ...uploadedData };
            delete ItemMerged.isUpdate;
            return ItemMerged;
          }
          return item;
        });
      } else if (uploadedData.isDelete) {
        itemsByField = itemsByField.filter((item, index) => {
          if (index === uploadedData.index) {
            return false;
          }
          return item;
        });
      }
      UploadedItems = itemsByField.concat(itemsByOtherFields);
    } else if (!isEmpty(uploadedData) && !isEmpty(uploadedData.items)) {
      //concat 2 arrays
      UploadedItems = UploadedItems.concat(uploadedData.items);
    }
    UploadedItems = sortBy(UploadedItems, ['field', 'order']);
    set(DefaultFields, `${uploadedData.field}`, [...UploadedItems]
      .filter(item => (item.field === uploadedData.field)));
    setAllUploadedItems([...UploadedItems].filter(item => !isEmpty(item)));
  }, [uploadedData]);

  const [formError, setFormError] = useState({});
  const [isFormSaveTriggered, setIsFormSaveTriggered] = useState(false);
  const CheckFormFieldValidity = (event, isSelectBox = false) => {
    const FieldError = CheckFieldValidity(event, isSelectBox);
    setFormError({ ...formError, ...FieldError });
  }

  const HandleChangeFunc = (event) => {
    HandleChange(event);
    CheckFormFieldValidity(event);
    if (!isEmpty(props.editItem.published_at)) {
      CheckFormFieldValidity(event);
    }
  }

  const [price, setPrice] = useState();
  const [max_price, setMaxPrice] = useState();
  const DecodePriceValue = (priceObj) => {
    set(DefaultFields, 'price', priceObj.floatValue);
    setPrice(priceObj.floatValue);
  }

  const DecodeMaxPriceValue = (maxpriceObj) => {
    set(DefaultFields, 'max_price', maxpriceObj.floatValue);
    setMaxPrice(maxpriceObj.floatValue);
  }

  const FormSubmitFunc = async (event, additionalParams = {}) => {
    try {
      const Result = await props.HandleSubmitFunc(event, {
        formId: 'edit-building-form',
        ...additionalParams,
        fields: { ...DefaultFields }
      });
      let selectBoxErrorObjTemp = {};
      if (isEmpty(selectBoxFieldErrors)) {
        selectBoxErrorObjTemp = { ...SelectBoxFieldDefaultErrors };
        setSelectBoxFieldErrors(selectBoxErrorObjTemp);
      } else if (find(selectBoxFieldErrors, function (sboxerror) {
        return (sboxerror.hasError)
      })) {
        selectBoxErrorObjTemp = selectBoxFieldErrors;
      }
      console.log('FormSubmitFunc', Result, selectBoxFieldErrors, SelectBoxFieldDefaultErrors, selectBoxErrorObjTemp)
      if (get(Result, 'formSubmit.hasError') || isEmpty(selectBoxErrorObjTemp)) {
        setFormError(Result.formSubmit.error);
        // console.log('formError', formError)
      } else if (Result.submittedResult) {
        // setAllUploadedItems([]);
        // setTabKey(0);
        // setBrochureUrl("");
        // setMainRegion(DefaultSelectValue);
        // setExtraRegions([]);
        // setMainBuildingManager(DefaultSelectValue);
        // setAssistantBuildingManagers([]);
        // setOffered(DefaultSelectValue);
        // setSelFacilities([]);
        // setHighLightFeatures([""]);                
      }
      setIsChildReload(!isChildReload);
    } catch (error) {
      console.log('FormSubmitFunc error', error);
    }
  }

  const [tabKey, setTabKey] = useState("0")
  const MoveToTab = async (event, key, additionalParams = {}) => {
    setIsFormSaveTriggered(true);
    const ChildNodes = document.getElementsByClassName('tab-pane active')[0];
    const TabError = CheckFormValidity(ChildNodes.getElementsByClassName('form-control'),
      { isSubmit: false, isCustom: true });
    console.log('FormError', TabError, key, tabKey);
    //Check any field has error
    let tabHasError = false;
    const TabErrorKeys = Object.keys(TabError),
      TabErrorKeysLength = TabErrorKeys.length;
    for (let index = 0; index < TabErrorKeysLength; index++) {
      tabHasError = TabError[TabErrorKeys[index]]?.hasError;
      // get(TabError, `${TabErrorKeys[index]}.hasError`);
      if (tabHasError) {
        setFormError(TabError);
        break;
      }
    }
    //Check selectbox va;idations
    let selectBoxErrorObjTemp = {};
    if (tabKey == "0") {
      selectBoxErrorObjTemp = { ...selectBoxFieldErrors };
      if (isEmpty(selectBoxErrorObjTemp)) {
        selectBoxErrorObjTemp = { ...SelectBoxFieldDefaultErrors };
        setSelectBoxFieldErrors(selectBoxErrorObjTemp);
      } else if (!isEmpty(find(merge(SelectBoxFieldDefaultErrors, selectBoxFieldErrors), function (sboxerror) {
        return (sboxerror.hasError)
      }))) {
        selectBoxErrorObjTemp = merge(SelectBoxFieldDefaultErrors, selectBoxFieldErrors);
        setSelectBoxFieldErrors(selectBoxErrorObjTemp);
      }
    }
    if ((!tabHasError) && (isEmpty(find(selectBoxErrorObjTemp, function (serror) {
      return serror.hasError;
    })))) {
      if (additionalParams.isSubmit) {
        try {
          await FormSubmitFunc(event, additionalParams);
          setTabKey(key);
          setIsFormSaveTriggered(false);
        } catch (error) {
          console.log('additionalParams.isSave', error);
        }
      } else {
        setTabKey(key);
        setIsFormSaveTriggered(false);
      }
    }
    MoveToTopOfPage();
  };

  const SaveCurrentTab = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let TabInc = (parseInt(tabKey) + 1).toString();
    let SubmitConfig = {
      isSubmit: true,
    };
    if (tabKey === '5') {
      TabInc = '0';
      SubmitConfig = {
        isSubmit: true,
        // isPageRedirect: true,
        publish: true,
        parentPath: props.parentPath,
        pageRedirectUrl: `/${props.parentPath}/list`,
        enableValidation: true
      }
    }
    MoveToTab(event, TabInc, { ...SubmitConfig });
  }

  const MoveToTopOfPage = () => {
    if (isBrowser()) {
      window.scrollTo(0, 0)
    }
  }

  const addAgentNotes = (index) => {
    if (agentNotes[index]) {
      setAgentNotes([...agentNotes, ""]);
    } else {
      alert('Please fill!');
    }
  }

  const removeAgentNotes = (index) => {
    let newAgentNotes = [...agentNotes];
    newAgentNotes.splice(index, 1);
    console.log('removeAgentNotes', newAgentNotes);
    setAgentNotes(newAgentNotes);
  }

  const handleAgentNotesChange = (event, index) => {
    let newAgentNotes = [...agentNotes];
    newAgentNotes[index] = event.target.value;
    setAgentNotes(newAgentNotes);
  }

  const handleAgentNotesKP = (event, index) => {
    console.log('handleAgentNotesKP', event)
    if (event.charCode === 13) {
      event.preventDefault();
      addAgentNotes(index);
    }
  }

  const addHighLightFeatures = (index) => {
    console.log('addHighLightFeatures', index, highLightFeatures)
    if (highLightFeatures[index]) {
      setHighLightFeatures([...highLightFeatures, ""]);
    } else {
      alert('Please fill!');
    }
  }

  const removeHighLightFeatures = (index) => {
    let newHighLightFeatures = [...highLightFeatures]
    newHighLightFeatures.splice(index, 1);
    setHighLightFeatures(newHighLightFeatures);
  }

  const handleHighLightFeaturesChange = (event, index) => {
    let newHighLightFeatures = [...highLightFeatures];
    newHighLightFeatures[index] = event.target.value;
    setHighLightFeatures(newHighLightFeatures);
  }

  const handleHighLightFeaturesKP = (event, index) => {
    console.log('handleHighLightFeaturesKP', event)
    if (event.charCode === 13) {
      event.preventDefault();
      addHighLightFeatures(index);
    }
  }

  const getUploadedData = (uploadedDataFromModal) => {
    console.log('getUploadedData', uploadedDataFromModal)
    setUploadedData(uploadedDataFromModal);
  }

  const GetUploadedUrl = async (event) => {
    try {
      const UploadedResult = await HandleAttachmentField(event, { ...props });
      set(DefaultFields, `${UploadedResult['field'] || 'media'}`, { url: UploadedResult.url, id: UploadedResult.id })
      setBrochureUrl(UploadedResult.url);
    } catch (error) {
      console.log('AddFormControls GetUploadedUrl error', error, event, props)
    }
  }

  const [showModalImages, setShowModalImages] = useState(false);
  const [showModalBrochure, setShowModalBrochure] = useState(false);

  const showModalImagesFunc = () => {
    setShowModalImages(true);
  }

  const setShowModalImagesFunc = () => {
    setShowModalImages(false);
  }

  const showModalBrochureFunc = () => {
    setShowModalBrochure(true);
  }

  const setShowModalBrochureFunc = () => {
    setShowModalBrochure(false);
  }

  const getMapLocation = (loc) => {
    setMapLoc(loc);
    setLatitude(loc.lat);
    setFormError({ ...formError, ...{ latitude: { hasError: false } } });
    setLongitude(loc.lng);
    setFormError({ ...formError, ...{ longitude: { hasError: false } } });
    // setDisplayAddress(loc.display_address);
  }

  const HandleMultiSelect = (event, name, copyInAddParam = false) => {
    if (copyInAddParam) {
      const SelectedOptions = event.map(option => option.value)
      set(DefaultFields, `${name}`, SelectedOptions);
    }
    if (SelectBoxFieldDefaultErrors[name]) {
      if (event?.value) {
        setSelectBoxFieldErrors({ ...selectBoxFieldErrors, ...{ [name]: { hasError: false } } });
      } else {
        setSelectBoxFieldErrors({ ...selectBoxFieldErrors, ...{ [name]: { hasError: (true) } } });
      }
    }
    switch (name) {
      case 'main_region':
        setMainRegion(event);
        if (event.value) {
          setSelectBoxFieldErrors({ ...selectBoxFieldErrors, ...{ main_region: { hasError: false } } });
        }
        break;
      case 'extra_regions':
        setExtraRegions(event);
        break;
      case 'main_building_manager':
        set(DefaultFields, `main_building_manager`, event.value);
        setMainBuildingManager(event);
        break;
      case 'assistant_building_managers':
        setAssistantBuildingManagers(event);
        break;
      case 'status':
        setOffered(event);
        if (event.value) {
          if (event.value === 'For Sale') {
            set(DefaultFields, `search_type`, 'sales');
          } else if (event.value === 'To Rent') {
            set(DefaultFields, `search_type`, 'lettings');
          }
        }
        break;
      case 'amenities':
        setSelFacilities(event);
        break;
      case 'currency':
        set(DefaultFields, 'currency', event);
        setCurrencyOption(event);
        break;
      case 'max_currency':
        set(DefaultFields, 'max_currency', event);
        setMaxCurrencyOption(event);
        break;
      default:
        break;
    }
  }

  const [checkboxValues, setCheckboxValues] = useState({});
  const HandleCheckBoxValue = (event) => {
    let checkBoxValueTemp = { [event.target.name]: event.target.checked };
    if ((event.target.name === 'is_enabled') && (!checkBoxValueTemp['is_enabled'])) {
      checkBoxValueTemp['is_show_on_evening_standard_site'] = false;
    }
    setCheckboxValues({ ...checkboxValues, ...checkBoxValueTemp });
  }

  const selectedChildProperties = ({ selectedRows }) => {
    set(DefaultFields, 'child_properties', selectedRows.map(property => {
      return property.id
    }))
  }

  const GoToListPage = () => {
    navigate("/buildings/list");
  }

  const editorChange = (content, editor, fieldName) => {
    console.log('editorChange', content, editor, fieldName);
  }

  return (
    <>
      <Form id="edit-building-form" noValidate onSubmit={(event) => SaveCurrentTab(event)}>
        <section className="agent-tabs">
          {
            isFormSaveTriggered && ((find((selectBoxFieldErrors), function (sboxerror) {
              return (sboxerror.hasError)
            })) || (find(formError, function (error) {
              return (error.hasError)
            }))) && <Alert key="danger" variant="danger">
              Highlighted fields are required
            </Alert>
          }
          <Tabs
            defaultActiveKey={"0"}
            transition={false}
            id="noanim-tab-example"
            className="mb-3"
            activeKey={tabKey}
            onSelect={(k) => MoveToTab({}, k)}
          >
            <Tab eventKey={"0"} title="Overview">
              <Row>
                <Col lg={12} className="d-flex justify-content-end">
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Save</Button>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Building Name *</label>
                    <input type="text" className={`form-control${isFormSaveTriggered && formError?.building_name?.hasError ? ' pu-has-control-error' : ''}`}
                      name="building_name"
                      autoFocus={true}
                      placeholder="Enter building name here"
                      onChange={(event) => HandleChangeFunc(event)}
                      required={true}
                      defaultValue={get(props, 'editItem.building_name')} />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Address 1 *</label>
                    <input type="text" className={`form-control${isFormSaveTriggered && formError?.address_line1?.hasError ? ' pu-has-control-error' : ''}`}
                      name="address_line1"
                      placeholder="Enter address here"
                      onChange={(event) => HandleChangeFunc(event)}
                      required={true}
                      defaultValue={get(props, 'editItem.address_line1')} />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Address 2</label>
                    <input type="text" className="form-control"
                      name="address_line2"
                      placeholder="Enter address here"
                      defaultValue={get(props, 'editItem.address_line2')} />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Address 3</label>
                    <input type="text" className="form-control"
                      name="address_line3"
                      placeholder="Enter address here"
                      defaultValue={get(props, 'editItem.address_line3')} />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Town / City *</label>
                    <input type="text" className={`form-control${isFormSaveTriggered && formError?.town?.hasError ? ' pu-has-control-error' : ''}`}
                      name="town"
                      placeholder="Enter town here"
                      onChange={(event) => HandleChangeFunc(event)}
                      required={true}
                      defaultValue={get(props, 'editItem.town')} />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>County *</label>
                    <input type="text" className={`form-control${isFormSaveTriggered && formError?.county?.hasError ? ' pu-has-control-error' : ''}`}
                      name="county"
                      placeholder="Enter county here"
                      onChange={(event) => HandleChangeFunc(event)}
                      required={true}
                      defaultValue={get(props, 'editItem.county')} />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Postcode *</label>
                    <input type="text" className={`form-control${isFormSaveTriggered && formError?.post_code?.hasError ? ' pu-has-control-error' : ''}`}
                      name="post_code"
                      placeholder="Enter postcode here"
                      onChange={(event) => HandleChangeFunc(event)}
                      required={true}
                      defaultValue={get(props, 'editItem.post_code')} />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Main Region *</label>
                    <div className={`custom-selectbox-form selectbox-dropdown${isFormSaveTriggered && selectBoxFieldErrors["main_region"]?.hasError ? ' pu-has-control-error' : ''}`}>
                      <Select
                        name="main_region"
                        options={regions}
                        placeholder="Please select main region"
                        className={`form-control${formError?.main_region?.hasError ? ' pu-has-control-error' : ''}`}
                        classNamePrefix={"select-opt"}
                        isSearchable={true}
                        onChange={(event) => HandleMultiSelect(event, 'main_region')}
                        required={true}
                        value={mainRegion}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Extra Regions</label>
                    <div className="custom-selectbox-form selectbox-dropdown">
                      <Select
                        name="extra_regions"
                        options={regions}
                        isMulti={true}
                        placeholder="Please select extra regions"
                        classNamePrefix={"select-opt"}
                        isSearchable={true}
                        onChange={event => HandleMultiSelect(event, 'extra_regions', true)}
                        value={extraRegions}
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <ul className="list-inline m-0">
                      <li className="list-inline-item m-0 me-5">
                        <div className="form-check dashboard-checkbox">
                          <input className="form-check-input" type="checkbox"
                            name="is_enabled"
                            value={true}
                            id="enabled"
                            checked={checkboxValues?.['is_enabled']}
                            onChange={event => HandleCheckBoxValue(event)} />
                          <label className="form-check-label" for="enabled">Enabled</label>
                        </div>
                      </li>
                      <li className="list-inline-item m-0">
                        <div className="form-check dashboard-checkbox">
                          <input className="form-check-input" type="checkbox"
                            name="is_show_on_evening_standard_site"
                            value={true}
                            id="standardsite"
                            checked={checkboxValues?.['is_show_on_evening_standard_site']}
                            onChange={event => HandleCheckBoxValue(event)} />
                          <label className="form-check-label" for="standardsite">Show on evening standard site</label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Offered</label>
                    <div className="custom-selectbox-form selectbox-dropdown">
                      <Select isDisabled={true}
                        name="status"
                        options={[
                          DefaultSelectValue,
                          { value: "For Sale", label: "For Sale" },
                          { value: "To Rent", label: "To Rent" }
                        ]}
                        placeholder="Please select offered"
                        classNamePrefix={"select-opt"}
                        isSearchable={false}
                        value={offered}
                        onChange={event => HandleMultiSelect(event, 'status')}
                      // styles={customStyles}
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Main Building Manager</label>
                    <div className="custom-selectbox-form selectbox-dropdown">
                      <Select
                        name="main_building_manager"
                        options={buildingManagers}
                        placeholder="Please select main building manager"
                        classNamePrefix={"select-opt"}
                        isSearchable={true}
                        value={mainBuildingManager}
                        onChange={event => HandleMultiSelect(event, 'main_building_manager')}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Assistant Building Managers</label>
                    <div className="custom-selectbox-form selectbox-dropdown">
                      <Select
                        name="assistant_building_managers"
                        isMulti={true}
                        options={buildingManagers}
                        placeholder="Please select assistant building managers"
                        classNamePrefix={"select-opt"}
                        isSearchable={true}
                        onChange={event => HandleMultiSelect(event, 'assistant_building_managers', true)}
                        value={assistantBuildingManagers}
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={5} className="mb-4">
                  <div className="d-flex justify-content-between flex-wrap">
                    <div className="form-wrapper w-100">
                      <label>Brochure</label>
                      {/* NOTE: Every input file field shoud have name field it mailyb used for upload the file in specific path */}
                      <input type="file" name="brochures" className="form-control file-attach"
                        onChange={(event) => GetUploadedUrl(event, 'custom-file1')} id="custom-file1"
                        placeholder="Click to attach" />
                    </div>
                    {
                      brochureUrl &&
                      <a target="_blank" href={brochureUrl}><p className="brochure-url">{brochureUrl}</p></a>
                    }
                  </div>
                </Col>
                <Col lg={1} className="mb-4">
                  <label> </label>
                  <Button className="btn btn-default" onClick={() => {
                    ClearAttachmentField('custom-file1');
                    setBrochureUrl('');
                  }}>Clear</Button>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Facilities</label>
                    <div className="custom-selectbox-form selectbox-dropdown">
                      <Select
                        name="amenities"
                        isMulti={true}
                        options={facilities}
                        placeholder="Please select facilities"
                        classNamePrefix={"select-opt"}
                        isSearchable={true}
                        onChange={event => HandleMultiSelect(event, 'amenities', true)}
                        value={selFacilities}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={10}>
                  <div className="form-wrapper">
                    <label>Highlight Features</label>
                  </div>
                  <div className="d-flex justify-content-start btn-wrapper">
                    <div className="mb-0 w-100">
                      {
                        highLightFeatures.map((element, i) => (
                          <div key={i} className="mb-4 d-flex justify-content-start">
                            <div className="form-wrapper w-100">
                              <input type="text" className={`form-control`} autoFocus={i > 0}
                                name={`special[${i}]`}
                                onChange={(event) => handleHighLightFeaturesChange(event, i)}
                                placeholder="Enter highlight features here"
                                onKeyPress={(event) => handleHighLightFeaturesKP(event, i)}
                                // onBlur={(event) => handleHighLightFeaturesFO(event, i)}
                                value={element} />
                            </div>

                            <div className="d-flex justify-content-between btn-add-delete">
                              <button type="button" className="btn-none" onClick={() => addHighLightFeatures(i)}>
                                <i className="icon icon-add-circle"></i>
                              </button>
                              {i > 0 &&
                                <button type="button" className="btn-none" onClick={() => removeHighLightFeatures(i)}>
                                  <i className="icon icon-delete-circle"></i>
                                </button>
                              }
                            </div>
                          </div>
                        ))
                      }
                    </div>                    
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12} className="mb-4">
                  <div className="form-wrapper">
                    <label>Short Description</label>
                    <TextEditor name="introtext" defaultValue={get(props, 'editItem.introtext')}
                      additionalParams={props.additionalParams}
                      editorChange={(content, editor) => editorChange(content, editor, 'introtext')} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12} className="mb-4">
                  <div className="form-wrapper">
                    <label>Main Description</label>
                    <TextEditor name="description"
                      defaultValue={get(props, 'editItem.description')}
                      additionalParams={props.additionalParams}
                      editorChange={(content, editor) => editorChange(content, editor, 'description')} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12} className="mb-4">
                  <div className="form-wrapper">
                    <label>Location Description</label>
                    <TextEditor name="long_description"
                      defaultValue={get(props, 'editItem.long_description')}
                      additionalParams={props.additionalParams}
                      editorChange={(content, editor) => editorChange(content, editor, 'long_description')} />
                  </div>
                </Col>
              </Row>
              <Row>                
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Private Notes</label>
                    <input type="text" className="form-control"
                      name="private_notes"
                      placeholder="Enter private notes here"
                      defaultValue={get(props, 'editItem.private_notes')} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={10}>
                  <div className="form-wrapper">
                    <label>Agent Notes</label>
                  </div>
                  <div className="d-flex justify-content-start btn-wrapper">
                    {/* {agentNotes} */}
                    <div className="mb-0 w-100">
                      {
                        agentNotes.map((element, i) => (
                          <div key={i} className="mb-4 d-flex justify-content-start">
                            <div className="form-wrapper w-100">
                              <input type="text" className={`form-control`} autoFocus={i > 0}
                                name={`agent_notes[${i}]`}
                                onChange={(event) => handleAgentNotesChange(event, i)}
                                placeholder="Enter agent notes here"
                                onKeyPress={(event) => handleAgentNotesKP(event, i)}
                                // onBlur={(event) => handleHighLightFeaturesFO(event, i)}
                                value={element} />
                            </div>
                            <div className="d-flex justify-content-between btn-add-delete">
                              <button type="button" className="btn-none" onClick={() => addAgentNotes(i)}>
                                <i className="icon icon-add-circle"></i>
                              </button>
                              {i > 0 &&
                                <button type="button" className="btn-none" onClick={() => removeAgentNotes(i)}>
                                  <i className="icon icon-delete-circle"></i>
                                </button>
                              }
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Bespoke Link URL</label>
                    <input type="text" className="form-control"
                      name="bespoke_link.url"
                      placeholder="Enter bespoke link url here"
                      defaultValue={get(props, 'editItem.bespoke_link.url')} />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Bespoke Link Text</label>
                    <input type="text" className="form-control"
                      name="bespoke_link.text"
                      placeholder="Enter bespoke link text here"
                      defaultValue={get(props, 'editItem.bespoke_link.text')} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12} className="mb-4">
                  <div className="form-wrapper">
                    <label>Broadband</label>
                    <TextEditor name="extra.broadband"
                      defaultValue={get(props, 'editItem.extra.broadband')}
                      additionalParams={props.additionalParams}
                      editorChange={(content, editor) => editorChange(content, editor, 'extra.broadband')} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="mb-4">
                  <Row>
                    <Col lg={3}>
                      <div className="form-wrapper">
                        <label>Currency</label>
                        <div className="custom-selectbox-form selectbox-dropdown">
                          <Select
                            name="currency"
                            options={CurrencyJson}
                            placeholder="Please select currency"
                            classNamePrefix={"select-opt"}
                            isSearchable={false}
                            // styles={customStyles}
                            value={currencyOption}
                            onChange={(event => HandleMultiSelect(event, 'currency'))}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="form-wrapper">
                        <label>Price</label>                        
                        <CurrencyFormat displayType={'input'} thousandSeparator={true}
                          name="price"
                          value={price}
                          placeholder="Enter price here"
                          className="form-control"
                          onValueChange={(values) => {
                            DecodePriceValue(values)
                            setPrice(DecodePriceValue(values))
                          }}
                          onChange={(event) => HandleChangeFunc(event)}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} className="mb-4">
                  <Row>
                    <Col lg={3}>
                      <div className="form-wrapper">
                        <label>Currecy</label>
                        <div className="custom-selectbox-form selectbox-dropdown">
                          <Select
                            name="max_currency"
                            options={CurrencyJson}
                            placeholder="Please select currency"
                            classNamePrefix={"select-opt"}
                            isSearchable={false}
                            // styles={customStyles}
                            value={maxCurrencyOption}
                            onChange={(event => HandleMultiSelect(event, 'max_currency'))}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className="form-wrapper">
                        <label>Max Price</label>
                        <CurrencyFormat
                          displayType={'input'} thousandSeparator={true}
                          name="max_price"
                          className="form-control"
                          placeholder="Enter max price"
                          value={max_price}
                          onValueChange={(values) => DecodeMaxPriceValue(values)}
                          onChange={(event) => HandleChangeFunc(event)}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="d-flex">
                  <ConfirmOnExitComp {...props} />
                </Col>
                <Col lg={6} className="d-flex justify-content-end">
                  <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "1")}>Next</Button>
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Save</Button>
                  </div>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey={"1"} title="Location">
              <Row>
                <Col lg={12}>
                  <p>Change the position of the map marker by moving the map.</p>
                  <div className="main-wrapper-map">
                    {
                      <GoogleMap getMapLocation={getMapLocation}
                        mapLoc={{
                          lat: latitude ? parseFloat(latitude) : mapLoc.lat,
                          lng: longitude ? parseFloat(longitude) : mapLoc.lng
                        }}
                        inputAddress={''}
                        actionType={mapActionType}
                      />
                    }                  
                  </div>
                  <p>
                    <div><span> Latitude: <b>{latitude}</b></span></div>
                    <div><span> Longitude: <b>{longitude}</b></span></div>
                  </p>
                  <input type="hidden" name="latitude" value={mapLoc.lat || 51.509865} />
                  <input type="hidden" name="longitude" value={mapLoc.lng || -0.118092} />
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="d-flex">
                  <ConfirmOnExitComp {...props} />
                  <div className="ms-3">
                    <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "0")}>Previous</Button>
                  </div>
                </Col>
                <Col lg={6} className="d-flex justify-content-end">
                  <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "2")}>Next</Button>
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Save</Button>
                  </div>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey={"2"} title="Images">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <p>Drag and drop assets - Property images. The order of the assets below is the order they will be shown on the website.</p>
                <div className="text-end">
                  <Button className="btn-primary-dashboard" onClick={() => showModalImagesFunc()}>Add</Button>
                </div>
              </div>
              {/* ModalPopUp */}
              {
                showModalImages && <ModalPopUp
                  modalShow={showModalImages}
                  getUploadedData={getUploadedData}
                  modalAction={'upload'}
                  setModalShowFunc={setShowModalImagesFunc}
                  uploadedDataFieldName="images"
                  parentPath="buildings"
                  format_text=".png, .jpg, .jpeg, .webp"
                  accept_format={{
                    'image/jpeg': ['.jpeg', '.png', '.jpg', '.webp']
                  }}
                />
              }
              {/* UploadedItemsTable */}
              <StatcTable
                menu="Buildings"
                parentPath="file"
                allItems={allUploadedItems.filter(item => (item.field === 'images'))}
                getUploadedData={getUploadedData}
              />
              <Row>
                <Col lg={6} className="d-flex">
                  <ConfirmOnExitComp {...props} />
                  <div className="ms-3">
                    <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "1")}>Previous</Button>
                  </div>
                </Col>
                <Col lg={6} className="d-flex justify-content-end">
                  <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "3")}>Next</Button>
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Save</Button>
                  </div>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey={"3"} title="Documents">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <p>Drag and drop assets - Property brochures. The order of the assets below is the order they will be shown on the website.</p>
                <div className="text-end">
                  <Button className="btn-primary-dashboard" onClick={() => showModalBrochureFunc()}>Add</Button>
                </div>
              </div>
              {/* ModalPopUp */}
              {
                showModalBrochure && <ModalPopUp
                  modalShow={showModalBrochure}
                  getUploadedData={getUploadedData}
                  modalAction={'upload'}
                  setModalShowFunc={setShowModalBrochureFunc}
                  uploadedDataFieldName="documents"
                  parentPath="buildings"
                  format_text=".pdf"
                  accept_format={{
                    'application/pdf': ['.pdf']
                  }}
                />
              }
              {/* UploadedItemsTable */}
              <StatcTable
                menu="Buildings"
                parentPath="file"
                allItems={allUploadedItems.filter(item => (item.field === 'documents'))}
                getUploadedData={getUploadedData}
              />
              <Row>
                <Col lg={6} className="d-flex">
                  <ConfirmOnExitComp {...props} />
                  <div className="ms-3">
                    <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "2")}>Previous</Button>
                  </div>
                </Col>
                <Col lg={6} className="d-flex justify-content-end">
                  <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "4")}>Next</Button>
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Save</Button>
                  </div>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey={"4"} title="Tours">
              <Row>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Video Url</label>
                    <input type="text" className="form-control"
                      name="video_tour.url"
                      placeholder="Enter video url here"
                      defaultValue={get(props, 'editItem.video_tour.url')} />
                  </div>
                </Col>
                <Col lg={6} className="mb-4">
                  <div className="form-wrapper">
                    <label>Virtual Tour URL</label>
                    <input type="text" className="form-control"
                      name="virtual_tour.url"
                      placeholder="Enter virtual tour url here"
                      defaultValue={get(props, 'editItem.virtual_tour.url')} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="d-flex">
                  <ConfirmOnExitComp {...props} />
                  <div className="ms-3">
                    <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "3")}>Previous</Button>
                  </div>
                </Col>
                <Col lg={6} className="d-flex justify-content-end">
                  <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "5")}>Next</Button>
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Save</Button>
                  </div>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey={"5"} title="Properties">
              {
                props?.editItem.id && <CommonTableComp
                  menu="Properties"
                  parentPath="child-properties"
                  pageType="List"
                  additionalParams={{
                    endPointName: "child-properties.getAll",
                    query: `department=residential&deleteAttempt=0&_where[_or][0][parent_building_null]=true&_where[_or][1][parent_building]=${props?.editItem.id}`
                  }}
                  childProperty={{
                    update: true,
                    parent_building: props?.editItem.id,
                    // childProperties: props?.editItem?.extra?.childProperties
                    childProperties: props?.editItem?.child_properties
                  }}
                  tableParams={{
                    selectableRows: true,
                    onSelectedRowsChange: ({ selectedRows }) => selectedChildProperties({ selectedRows })
                  }}
                  isChildReload={isChildReload}
                />
              }
              <Row>
                <Col lg={6} className="d-flex">
                  <ConfirmOnExitComp {...props} />
                  <div className="ms-3">
                    <Button className="btn-primary-dashboard" onClick={(event) => MoveToTab(event, "4")}>Previous</Button>
                  </div>
                </Col>
                <Col lg={6} className="d-flex justify-content-end">
                  <div className="text-end ms-3">
                    <Button type="submit" className="btn-primary-dashboard">Submit</Button>
                  </div>
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </section>
      </Form>
    </>
  )
}

const EditBuilding = (props) => {
  return <CommonEditItemComp PuFormControlsEdit={
    (HandleSubmitFunc, SetPublishActionAvailability, item) => {
      return (
        <>
          <EditFormControls
            {...props} HandleSubmitFunc={HandleSubmitFunc}
            SetPublishActionAvailability={SetPublishActionAvailability}
            editItem={item}
          />
        </>
      )
    }
  } {...props} DisableDefaultFormAction={true} />
}

export default EditBuilding;
